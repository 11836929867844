/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://www.error500.net/smart-cities-y-cuando-se-cambian-sensores-por-camaras/"
  }, "Ésta es ya una noticia que tiene un tiempo"), ", pero tenía que hablar sobre ella. Entiendo que se utilicen cámaras en lugar de sensores por la riqueza que dan en información, pero tampoco nos podemos confiar y olvidarnos de nuestra privacidad. El principal problema viene de utilizar un procesado en la nube."), "\n", React.createElement(_components.p, null, "Si el procesado se realiza ", React.createElement(_components.em, null, "in-situ"), " y la información que se transmite es simplemente datos ya en limpio que no identifican a una persona en concreto no tiene por qué haber más problemas, “Se ha sentado una mujer joven”, “Se ha sentado un padre con su hijo” o incluso “La mujer que se ha sentado antes está impaciente”, de esta forma no vulneramos la privacidad de nadie y es más difícil que nos crackeen la cámara."), "\n", React.createElement(_components.p, null, "Si se envía la imagen de la cámara en bruto por las redes, sean públicas o privadas es posible que alguien acceda a éstas imágenes. Igual no ahora pero en un futuro cuando los ordenadores tengan una mayor capacidad de procesamiento, es posible que alguien con un programa de reconocimiento de caras pueda trazar tu camino, a través de estas cámaras y las que ya hay para videovigilancia, y todo sin tú saberlo."), "\n", React.createElement(_components.p, null, "Sólo era una pequeña reflexión, pero pienso que el tema de las cámaras como sensores se debería regular, más de lo que ya de por sí está como cámaras que están en un lugar público, un cartelito no es suficiente."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
